<template>
    <div 
        class="sidebar-container" 
        v-if="store.getters.sidebar" 
    >
        <div class="background animate__animated animate__fadeIn" @click="closeSidebar"></div>
        <div class="content-container animate__animated animate__fadeInRight">
            <div class="sidebar-top">
                <img class="img" src="../../assets/img/header/dark-header-01.png" alt="Logotipo Comprodonto" />
                <span @click="closeSidebar" class="material-icons-outlined iconClose">close</span>
            </div>
            <ul class="list">
                
                <li class="itemsList" @click="() => redirect('/sobre')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    Sobre nós
                </li>
                <li class="itemsList" @click="() => redirect('/duvidas')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    Dúvidas
                </li>
                <li class="itemsList" @click="() => redirect('/contato')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    Contato
                </li>
                <li class="itemsList" @click="() => redirect('https://blog.comprodonto.com.br')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    Blog
                </li>
                <li v-if="isMobile" class="itemsList" @click="() => redirect(store.getters.textSidebar === 'Login' ? 'login'  : '/minha-area')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    {{ store.getters.textSidebar }}
                </li>
                <li class="itemsList" @click="() => openModal('terms')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    Termos de uso
                </li>
                <li class="itemsList" @click="() => openModal('politics')">
                    <span class="material-icons-outlined iconArrowRight">chevron_right</span>
                    Política de privacidade
                </li>
            </ul>
            <div class="social-container">
                <p class="contato">
                    <span class="material-icons-outlined icon-contato">phone_iphone</span>
                    +55 (35) 9 3300-7935
                </p>
                <p class="contato">
                    <span class="material-icons-outlined icon-contato">email</span>
                    comprodontoanuncio@gmail.com
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { createEventosSite } from '../../services/util';
export default {
    name: "Sidebar",
    props: {
        isMobile: Boolean
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const closeSidebar = () => store.commit("setSidebar", false);

        const redirect = (url) => {
            if (url === 'login') {
                store.commit("setModalLogin", {display: true});
                createEventosSite('VISUALIZACAO', 'modal_login'); 
            } else {
                if (url.includes('http')) {
                    window.open(url)
                    createEventosSite("CLICK", "blog");
                } else {
                    router.push(url);
                }
            }
            closeSidebar();
        }

        const openModal = (modalType) => store.commit("setModalTermsAndPolitics", {display: true, content: modalType});

        return { closeSidebar, redirect, openModal, store, router }
    },
}
</script>

<style scoped>
    .sidebar-container{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
        animation : fadeIn left 0.5s ease-in-out  ;
    }

    .background{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.33);
        width: 100%;
        height: 100vh;
    }

    .content-container{
        position: fixed;
        top: 0;
        right: 0;
        background-color: #fff;
        width: 30.3125rem;
        height: 100vh;
        padding: 2rem 4rem;
    }

    .sidebar-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .img{
        user-select: none;
    }

    .iconClose{
        color: #C90C53;
        font-size: 2rem;
        user-select: none;
        cursor: pointer;
    }

    .iconArrowRight{
        color: #ff6ea9;
        font-size: 1.4rem;
    }

    .list{
        list-style: none;
        padding: 0;
        text-align: left;
        margin: 4rem 0;
    }

    .itemsList{
        display: flex;
        align-items: center;
        user-select: none;
        font-weight: 600;
        font-size: .875rem;
        margin-bottom: 1rem;
        cursor: pointer;
    }

    .social-container{
        text-align: left;
        color: #212529;
        font-weight: 400;
    }

    .contato{
        display: flex;
        align-items: center;
        font-size: .875rem;
        line-height: .75rem;
    }

    .icon-contato{
        font-size: 1.1rem;
        margin-right: .8rem;
        user-select: none;
    }

    @media screen and (max-width: 500px) {
        .content-container{
            width: 100%;
            padding: 2rem 1.5rem;
        }
    }
</style>