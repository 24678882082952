<template>
    <Navbar type="default" :background="true" :isMobile="isMobile" />
    <template v-if="Object.keys(detalhesPlano).length > 0">
        <BannerPlano 
            :plan="detalhesPlano.plano" 
            :arquivos="detalhesPlano.arquivos" 
            :operadora="detalhesPlano.operadora" 
            :isMobile="isMobile" 
            :pessoa_juridica="detalhesPlano.faixas"
            :tipo_plano="detalhesPlano.plano.tipo_plano"
        />
        <Details :plan="detalhesPlano.plano" :abrangencia="detalhesPlano.abrangencia" :isMobile="isMobile" />
    </template>
    <div v-else class="height"></div>
    <Footer type="default" :isMobile="isMobile" />
</template>

<script v-html="schema" type="application/ld+json"></script>
<script>
import Navbar from '../components/Elements/Navbar.vue';
import BannerPlano from '../components/DetalhesPlano/BannerPlano.vue';
import Details from '../components/DetalhesPlano/Details.vue';
import Footer from '../components/Elements/Footer.vue';
import { inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { GET } from '../services/api';

export default {
    name: "DetalhesPlano",
    components: {
        Navbar,
        BannerPlano,
        Details,
        Footer
    },
    created() {
        this.isMobile = window.innerWidth < 800 ?  true : false;
        window.scrollTo(0,0);
        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 800 ?  true : false;
        })
    },
    setup() {
        const loader = inject("loading");
        const alert = inject("alert");
        const route = useRoute();
        const router = useRouter();
        
        const isMobile = ref(false);
        const detalhesPlano = ref({});
        const schema = ref({});
        // const content = ref("");

        onMounted( async () => {
            loader.open();
            let id;
            try {
/*                 const pessoa_juridica= [
                    {
                        active: "1",
                        price: 10,
                        min: "10",
                        max: "20",
                        setup_fee: 10,
                        valor_boleto: 10,
                        carencia: "10",
                        carencia_boleto: "10"
                    },
                    {
                        active: "0",
                        price: 20,
                        setup_fee: 20,
                        valor_boleto: 20,
                        carencia: "10",
                        carencia_boleto: "10",
                        min: "21",
                        max: "30"
                    }
                ] */
                detalhesPlano.value = await GET(`detalhes/plano/${route.params.id}`);
                //detalhesPlano.value.pessoa_juridica = pessoa_juridica
                id = detalhesPlano.value.operadora.id;
                schema.value = {
                    "@context": "https://schema.org",
                    "@type": "Product", //tipo do schema, podem ter listas, ranking e avaliações, podemos consultar os demais tipos em https://schema.org,
                    image: [detalhesPlano.value.plano.foto_plano], //Imagem referente ao produto,
                    name: detalhesPlano.value.plano.name,
                    description: `Plano odontologico ${detalhesPlano.value.operadora.end_cidade}`,
                    brand: {
                        "@type": "Brand",
                        name: "Uniodonto", //Marca detentora do produto
                    },
                    offers: {
                        "@type": "Offer",
                        url: window.location.href,
                        itemCondition: "http://schema.org/NewCondition",
                        availability: "http://schema.org/InStock",
                        price: detalhesPlano.value.plano.price,
                        priceCurrency: "BRL",
                    },
                    review: {
                        "@type": "Review",
                        reviewRating: {
                            "@type": "Rating",
                            ratingValue: "5",
                            bestRating: "5",
                        },
                        author: {
                            "@type": "Person",
                            name: "Matheus",
                        },
                    },
                    aggregateRating: {
                        "@type": "AggregateRating",
                        ratingValue: "4.4",
                        reviewCount: "89",
                    },
                };
            } catch (e) {
                alert.open("Erro", "Desculpe, não foi possivel buscar informações sobre o plano!");
                router.back();
            }

            
            // try {
            //     const marketing = await GET(`operadoras/${id}/marketing`);
            //     // content.value = marketing.popup_texto;
            // } catch (e) {
            //     alert.open("Erro", "Desculpe, ocorreu um erro ao buscar por marketing!");
            // }
            loader.close();
        })

        return { detalhesPlano, isMobile, schema }
    },
}
</script>

<style scoped>
    .height{
        height: 100vh;
        width: 100%;
    }
</style>